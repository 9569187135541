<template>
  <div>
    <v-snackbar
      v-model="isSnackbarConfirmCheckKM"
      color="deep-purple"
      elevation="24"
      :timeout="-1"
      centered
    >
      <p style="margin-bottom: 0px;">Introduce tus KM de hoy</p>
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="isSnackbarConfirmCheckKM = false">
          OK
        </v-btn>
      </template>
    </v-snackbar>
    <v-card elevation="2" outlined shaped tile class="mb-2">
      <v-card-text>
        <v-row v-if="isNotStartedYet">
          <v-col cols="12" sm="1" md="2"></v-col>
          <v-col cols="12" sm="10" md="8">
            <v-alert
              outlined
              type="warning"
              prominent
              border="left"
              color="#C51162"
              dark
            >
              Recuerda iniciar tu jornada.
            </v-alert>
          </v-col>
          <v-col cols="12" sm="1" md="2"></v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="12" class="text-center">
            <v-btn
              :class="
                `btn_standard_calculator ${
                  isEnded ? '' : isStarted ? 'btn_started' : 'btn_ended'
                }`
              "
              :loading="isClickingStart"
              :disabled="!isBtnReady"
              @click="onClickStartWorkday(!isStarted)"
              >{{
                isEnded
                  ? $t("WORKDAY ENDED")
                  : isStarted
                  ? $t("END WORKDAY")
                  : $t("START WORKDAY")
              }}</v-btn
            >
            <v-btn
              :class="
                `btn_standard_calculator ${
                  !isEnded && isPaused ? 'btn_paused' : 'btn_resumed'
                }`
              "
              :loading="isClickingPause"
              :disabled="!isBtnReady"
              @click="onClickPause"
              >{{
                isEnded ? "-" : isPaused ? $t("RESUME") : $t("PAUSE")
              }}</v-btn
            >
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="2" md="3"></v-col>
          <v-col cols="12" sm="4" md="3">
            <span class="title">{{ $t("Employee") }}: </span>
            <span class="title_values">{{
              user.name + " " + user.surname
            }}</span>
          </v-col>
          <v-col cols="12" sm="4" md="3">
            <span class="title">{{ $t("Date") }}: </span>
            <span class="title_values">{{
              currentDate.toLocaleDateString("en-GB")
            }}</span>
          </v-col>
          <v-col cols="12" sm="2" md="3"></v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="2" md="3"></v-col>
          <v-col cols="12" sm="4" md="3">
            <span class="title">{{ $t("Hours Worked") }}: </span>
            <span class="title_values">{{ current_worked_duration }}</span>
          </v-col>
          <v-col cols="12" sm="4" md="3">
            <span class="title">{{ $t("Hours Paused") }}: </span>
            <span class="title_values">{{ current_paused_duration }}</span>
          </v-col>
          <v-col cols="12" sm="2" md="3"></v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="1" md="2"></v-col>
          <v-col cols="12" sm="10" md="8">
            <ve-table
              id="workday-table"
              ref="tableRef"
              fixed-header
              max-height="calc(100vh - 330px)"
              :columns="columns"
              :table-data="tableData"
              :border-around="true"
              :border-x="true"
              :border-y="true"
            />
            <div v-show="showEmpty" class="empty-data">Data Empty.</div>
          </v-col>
          <v-col cols="12" sm="1" md="2"></v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ApiService from "@/services/apiService";
import { logInfo, logError } from "@/utils";
import moment from "moment";
import _ from "lodash";

export default {
  name: "calculator",
  data() {
    return {
      isStarted: false,
      isPaused: false,
      isEnded: false,
      isClickingStart: false,
      isClickingPause: false,
      isWorkableToday: true,
      currentDate: new Date(),
      tableData: [],
      showEmpty: false,
      loadingInstance: null,
      firstItem: null,
      lastItem: null,
      logTimeIntervalId: null,

      current_duration: "00:00:00",
      current_worked_duration: "00:00:00",
      current_paused_duration: "00:00:00",
      isBtnReady: false,
      isNotStartedYet: false,
      isSnackbarConfirmCheckKM: false,
    };
  },
  computed: {
    ...mapGetters("auth", ["isAuthenticated", "isGPV", "user"]),
    columns() {
      return [
        {
          key: "indexNum",
          title: "ID",
          field: "indexNum",
          width: "10%",
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return <span class="text-bold">{row.indexNum + 1}</span>;
          },
        },
        {
          key: "logTypeLabel",
          title: this.$t("Type"),
          field: "logTypeLabel",
          width: "25%",
        },
        {
          key: "duration",
          title: this.$t("time"),
          field: "duration",
          width: "25%",
        },
        {
          key: "startMoment",
          title: this.$t("Start") + " hh:mm:ss",
          field: "startMoment",
          width: "20%",
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return (
              <span class="text-bold">
                {row.startMoment
                  ? moment(row.startMoment)
                      .local()
                      .format("HH:mm:ss")
                  : ""}
              </span>
            );
          },
        },
        {
          key: "endMoment",
          title: this.$t("End") + " hh:mm:ss",
          field: "endMoment",
          width: "20%",
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return (
              <span class="text-bold">
                {row.endMoment
                  ? moment(row.endMoment)
                      .local()
                      .format("HH:mm:ss")
                  : ""}
              </span>
            );
          },
        },
      ];
    },
  },
  methods: {
    show() {
      this.loadingInstance = this.$veLoading({
        target: document.querySelector("#workday-table"),
        name: "flow", // gird, flow, wave, bounce,
      });
      this.loadingInstance.show();
    },
    close() {
      this.loadingInstance.close();
    },
    checkUserDidStartWorkForToday() {
      if (
        !this.isEnded &&
        !this.isStarted &&
        !this.isPaused &&
        this.isWorkableToday
      ) {
        let current_day = moment(this.currentDate).day();
        if (current_day !== 0 && current_day !== 6) {
          let currentTime = moment(this.currentDate).format(
            "YYYY-MM-DD HH:mm:ss"
          );
          let logDate = moment(this.currentDate)
            .local()
            .format("YYYY-MM-DD");
          let startShouldTime = moment(new Date(logDate + " 10:00:00")).format(
            "YYYY-MM-DD HH:mm:ss"
          );
          if (startShouldTime < currentTime) {
            this.isNotStartedYet = true;
          }
        }
      } else {
        this.isNotStartedYet = false;
      }
    },
    async getDataFromApi() {
      this.show();
      let logDate = moment(this.currentDate)
        .local()
        .format("YYYY-MM-DD");
      let fetch_url = "workday/getdataperdate?logdate=" + logDate;
      try {
        const response = await ApiService.get(fetch_url);
        this.isBtnReady = true;
        this.isWorkableToday = response.isWorkableToday;
        this.tableData = response.data;
        if (this.tableData.length > 0) {
          this.showEmpty = false;
        } else {
          this.showEmpty = true;
        }
        if (this.tableData.length > 0) {
          this.firstItem = this.tableData[0];
          this.lastItem = this.tableData[this.tableData.length - 1];
          if (this.lastItem.endStatus === "YES") {
            this.isEnded = true;
            // this.setCurrentDuration();
            this.setWorkedPauseDuration();
          } else {
            this.isStarted = true;
            if (this.lastItem.logType === "PAUSE") {
              this.isPaused = true;
            }
          }
        } else {
          this.isEnded = false;
        }
        if (!this.isEnded && this.isStarted && !this.logTimeIntervalId) {
          this.logTimeIntervalId = setInterval(this.logTimeTick, 1000);
        } else if (this.isEnded && this.logTimeIntervalId !== null) {
          clearInterval(this.logTimeIntervalId);
        }
        // this.checkUserDidStartWorkForToday();
        this.close();
      } catch (error) {
        this.close();
      }
    },
    logTimeTick() {
      // this.setCurrentDuration();
      this.setWorkedPauseDuration();
      this.setEndLastDateWorkday();
    },
    setCurrentDuration() {
      let start = moment(this.firstItem.startMoment)
        .local()
        .format("YYYY-MM-DD HH:mm:ss");
      let end = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
      this.current_duration = this.get_duration(start, end);
    },
    setWorkedPauseDuration() {
      const { workedTime, pausedTime } = this.get_duration_with_criteria(
        this.tableData
      );
      this.current_worked_duration = workedTime;
      this.current_paused_duration = pausedTime;
    },
    async setEndLastDateWorkday() {
      let focus_date = moment(new Date())
        .local()
        .format("YYYY-MM-DD");
      let current_mounted_date = moment(this.currentDate)
        .local()
        .format("YYYY-MM-DD");
      console.log(
        focus_date,
        current_mounted_date,
        focus_date > current_mounted_date,
        focus_date < current_mounted_date
      );
      if (focus_date > current_mounted_date) {
        window.location.reload(true);
      }
    },
    async logWorkday(isStarted, isPaused) {
      let logDate = moment(new Date())
        .local()
        .format("YYYY-MM-DD");
      let data = {
        isStarted,
        isPaused,
        logDate,
        currentDate: new Date(),
        endableMoment: new Date(logDate + " 23:59:59"),
      };
      try {
        await ApiService.post("workday/logworkday", data);
      } catch (error) {
        logError("Error !");
        window.location.reload(true);
      }
    },
    async onClickStartWorkday(started) {
      if (!this.isWorkableToday) {
        logError(
          "No puedes empezar la  laboral porque estás de vacaciones o de baja."
        );
        return;
      }
      if (!this.isEnded) {
        this.isClickingStart = true;
        if (started) {
          this.isPaused = false;
          this.isStarted = started;
          await this.logWorkday(this.isStarted, false);
          this.getDataFromApi();
        } else {
          if (window.confirm("Vas a finalizar la jornada,  estás seguro?")) {
            this.isEnded = true;
            this.isStarted = started;
            await this.logWorkday(this.isStarted, false);
            this.getDataFromApi();
            this.isClickingStart = false;
            if (this.isGPV) {
              try {
                let logDate = moment(new Date())
                  .local()
                  .format("YYYY-MM-DD");
                let checkkm_response = await ApiService.get(
                  `workday/checkhaskmdatafortoday/${this.user.id}/${logDate}`
                );
                if (!checkkm_response.success) {
                  this.isSnackbarConfirmCheckKM = true;
                } else {
                  this.isSnackbarConfirmCheckKM = false;
                }
              } catch (error) {
                console.log(error);
              }
            }
          }
        }
        this.isClickingStart = false;
      }
    },
    async onClickPause() {
      if (this.isStarted) {
        this.isClickingPause = true;
        this.isPaused = !this.isPaused;
        await this.logWorkday(this.isStarted, this.isPaused);
        this.getDataFromApi();
        this.isClickingPause = false;
      }
    },
    init() {
      this.getDataFromApi();
    },
  },
  mounted() {
    this.init();
  },
  destroyed() {
    if (this.logTimeIntervalId !== null) {
      clearInterval(this.logTimeIntervalId);
    }
  },
};
</script>

<style>
.btn_standard_calculator {
  min-width: 200px !important;
  height: 80px !important;
  border: 2px solid #555;
  border-radius: 10px;
  margin: 10px 10px;
  font-size: 1rem !important;
  background: #afabab !important;
  color: #fff !important;
}
.btn_started {
  background: #c00000 !important;
  color: #fff !important;
}
.btn_ended {
  background: #92d050 !important;
  color: #000000de !important;
}
.btn_paused {
  background: #92d050 !important;
  color: #000000de !important;
}
.btn_resumed {
  background: #afabab !important;
  color: #fff !important;
}
.v-application .title_values {
  font-size: 1.5em !important;
  color: #000;
}
</style>
